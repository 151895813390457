import { api } from '@@/services/api';
import { Roles } from 'front-ps/lib/src/constants/user';
import { convertToFormData } from 'front-ps/lib/src/utils/formData';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPatient: builder.mutation<
      Unpatient.User & Unpatient.SigninResult,
      Partial<Unpatient.User>
    >({
      query: ({ photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.append('file', photo);
        }

        return {
          url: '/users',
          method: 'POST',
          body: convertToFormData(
            {
              ...body,
              role: Roles.PATIENT,
            },
            formData,
          ),
          formData: true,
        };
      },
      invalidatesTags: ['Users'],
    }),
    getMe: builder.query<Unpatient.User, void>({
      query: () => 'users/me',
      providesTags: ['Users'],
    }),
    patchMe: builder.mutation<Unpatient.User, Partial<Unpatient.User>>({
      query: ({ id, photo, ...body }) => {
        const formData = new FormData();

        if (photo) {
          formData.append('file', photo);
        }

        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body: convertToFormData(body, formData),
          formData: true,
        };
      },
      invalidatesTags: ['Users', 'Histories'],
    }),
    deleteMe: builder.mutation<Unpatient.User, void>({
      query: () => {
        return {
          url: '/users/me',
          method: 'DELETE',
          body: {},
        };
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useCreatePatientMutation,
  usePatchMeMutation,
  useDeleteMeMutation,
} = userApi;
