import { api } from '@@/services/api';

export const prescriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyPrescriptions: builder.query<Unpatient.Prescription[], void>({
      query: () => 'prescriptions/mine',
      providesTags: ['Prescriptions'],
    }),
    getPrescription: builder.query<Unpatient.Prescription, string>({
      query: (prescriptionId) => `prescriptions/${prescriptionId}`,
      providesTags: ['Prescriptions'],
    }),
    viewPrescriptionPDF: builder.query<string, string>({
      query: (prescriptionId) => ({
        url: `prescriptions/${prescriptionId}/pdf`,
        responseHandler: async (response) => {
          const contentType = response.headers.get('Content-Type');
          const blob = await response.blob();

          return URL.createObjectURL(
            contentType ? new Blob([blob], { type: contentType }) : blob,
          );
        },
      }),
    }),
  }),
});

export const {
  useGetMyPrescriptionsQuery,
  useGetPrescriptionQuery,
  useViewPrescriptionPDFQuery,
} = prescriptionApi;
