import React from 'react';
import {
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Layout from '@@/components/Layout';
import PrivateRoute from '@@/components/PrivateRoute';
import VanillaPrivateRoute from '@@/components/VanillaPrivateRoute';
import StartPage from '@@/pages/StartPage';
import SigninPage from '@@/pages/SigninPage';
import MePage from '@@/pages/MePage';
import PrescriptionPage from '@@/pages/PrescriptionPage';
import ReferralPage from '@@/pages/ReferralPage';
import PlanPage from '@@/pages/PlanPage';
import ResetPasswordPage from '@@/pages/ResetPasswordPage';
import ChangePasswordPage from '@@/pages/ChangePasswordPage';
import PrivacyPolicyPage from '@@/pages/PrivacyPolicyPage';
import FAQPage from '@@/pages/FAQPage';
import ContactPage from '@@/pages/ContactPage';
import Fallback from 'front-ps/lib/src/components/Fallback';

Sentry.init({
  dsn: 'https://6c66df318781fab0defcd85f107568a5@o4506553284689920.ingest.us.sentry.io/4507339153014784',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Fallback />,
    children: [
      {
        path: '/signin',
        index: true,
        element: <SigninPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/change-password',
        element: <ChangePasswordPage />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: '/start',
        element: <StartPage />,
      },
      {
        path: '/contact',
        element: <ContactPage />,
      },
      {
        path: '/faq',
        element: <FAQPage />,
      },
      {
        path: '/me',
        element: <PrivateRoute />,
        children: [{ index: true, element: <MePage /> }],
      },
      {
        path: '/prescriptions/:prescriptionId',
        element: <VanillaPrivateRoute />,
        children: [{ index: true, element: <PrescriptionPage /> }],
      },
      {
        path: '/referrals/:referralId',
        element: <VanillaPrivateRoute />,
        children: [{ index: true, element: <ReferralPage /> }],
      },
      {
        path: '/plans/:planId',
        element: <VanillaPrivateRoute />,
        children: [{ index: true, element: <PlanPage /> }],
      },
      {
        path: '*',
        element: <SigninPage />,
      },
    ],
  },
]);

export default router;
