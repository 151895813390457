export var Roles;
(function (Roles) {
    Roles["ADMIN"] = "admin";
    Roles["PRACTITIONER"] = "practitioner";
    Roles["PATIENT"] = "patient";
})(Roles || (Roles = {}));
export var Statuses;
(function (Statuses) {
    Statuses["ACTIVE"] = "active";
    Statuses["INACTIVE"] = "inactive";
})(Statuses || (Statuses = {}));
export const selectInputStatuses = [
    {
        id: Statuses.ACTIVE,
        label: 'Actif',
    },
    {
        id: Statuses.INACTIVE,
        label: 'Inactif',
    },
];
export var Titles;
(function (Titles) {
    Titles["Dr"] = "Dr";
    Titles["Pr"] = "Pr";
    Titles["M"] = "M";
    Titles["Mme"] = "Mme";
})(Titles || (Titles = {}));
export const selectInputTitles = [
    {
        id: Titles.Dr,
        label: Titles.Dr,
    },
    {
        id: Titles.Pr,
        label: Titles.Pr,
    },
    {
        id: Titles.M,
        label: Titles.M,
    },
    {
        id: Titles.Mme,
        label: Titles.Mme,
    },
];
