export const convertToFormData = (object, form, namespace) => {
    const formData = form || new FormData();
    for (const key of Object.keys(object)) {
        const value = object[key];
        if ([null, undefined, ''].includes(value)) {
            continue;
        }
        if (typeof value === 'object' && !(value instanceof Date)) {
            convertToFormData(value, formData, namespace ? `${namespace}[${key}]` : key);
        }
        else {
            const formKey = namespace ? `${namespace}[${key}]` : key;
            formData.append(formKey, String(value));
        }
    }
    return formData;
};
