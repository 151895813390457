import React, { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useResetPasswordMutation } from '@@/services/auth';
import ResetPasswordForm, {
  schema,
} from '@@/components/Forms/ResetPasswordForm';
import { Roles } from 'front-ps/lib/src/constants/user';

const ResetPasswordPage: React.FC = () => {
  const form = useForm<{ username: string }>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const [resetPassword, { isSuccess }] = useResetPasswordMutation();

  const onSubmit = useCallback(
    (data: { username: string }) => {
      resetPassword({ ...data, role: Roles.PATIENT });
    },
    [resetPassword],
  );

  useEffect(() => {
    if (isSuccess) {
      form.reset();
      toast.success(
        'Un email de réinitialisation de mot de passe vous a été envoyé',
      );
    }
  }, [isSuccess, form.reset]);

  return (
    <div className="my-6 mx-auto sm:w-[500px]">
      <div className="mx-4">
        <p className="font-main text-main mb-10">
          Pour réinitialiser votre mot de passe, indiquez votre email
        </p>
        <ResetPasswordForm form={form} />

        <button
          type="button"
          className="mt-10 py-2 px-3 bg-cyan-400 text-white rounded-xl font-main text-base w-fit"
          onClick={form.handleSubmit(onSubmit)}
          disabled={form.formState.isSubmitting}
        >
          <span className="ml-2">Valider</span>
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
